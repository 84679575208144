import { useLoadScript } from '@react-google-maps/api'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import { useA2AStoreQuery } from 'hooks/use-a2a-store-query'
import { Helmet } from 'react-helmet'
import Layout from '../../Layout'
import { MappaPrincipale } from '../../components/Mappa/MappaPrincipale'
import { A2ADataContextProvider } from '../../contexts/A2ADataContext'
import { LayoutContextProvider } from '../../contexts/LayoutContext'

const MappaPage = () => {
  const a2aStores = useA2AStoreQuery()

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_KEY || '',
    libraries: ['places'],
  })

  return (
    <A2ADataContextProvider>
      <LayoutContextProvider>
        <Layout
          pageTitle="Mappa Sportelli e Spazio A2A | A2A Energia"
          footerType={'simple'}
        >
          <Helmet>
            <meta
              name="description"
              content="Scopri gli Spazi A2A e i nostri Sportelli commerciali più vicino a te consultando la mappa con tutti i dettagli relativi."
            />
          </Helmet>

          <CanonicalUrlComponent url={`/assistenza/mappa`} />

          <MappaPrincipale
            isLoaded={isLoaded}
            stores={a2aStores}
            mapData={a2aStores[0]?.mapData}
            isInMapPage={true}
          />
        </Layout>
      </LayoutContextProvider>
    </A2ADataContextProvider>
  )
}

export default MappaPage
